/* eslint-disable no-void */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ChangeEvent, useEffect, useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FullFlexibleSimulationValues, fullFlexibleValidationSchema, useFullFlexible } from './useFullFlexible';
import {
  CheckboxField,
  DatepickerField,
  InputField,
  NumericFormatField, RadioField, SelectField, SliderField,
} from '../../Forms/Fields';
import {
  CompensationPlan, ExtraCompensationInsured, ExtraCompensationInsuredOptions, GenderOptions, Genders, SavingsOption, SavingsTypeOptions, SevereDiseaseAssuranceRate,
} from '../../../utils/constants';
import { useMinimumCapital } from '../../../hooks/useMinimumCapital';
import { handleRutChange } from '../../../utils/formHandlers';
import { mapQueryParamsToRecommendationPayload } from '../../RecommenderForms/common/queryParams';
import { transformCLPToUF } from '../../../utils/user';
import { RadioBoxField } from '../../Forms/Fields/RadioBoxField';

export function FullFlexibleForm() {
  const [minimumCapital, setMinimumCapital] = useState(0);
  const [repeatSimulation, setRepeatSimulation] = useState(false);
  const { handleSubmitSimulation } = useFullFlexible();

  // Load query parameters and tries to fill the default values based on them
  const searchParams = new URLSearchParams(window.location.search);
  const valuesFromRecommender = mapQueryParamsToRecommendationPayload(searchParams);

  const methods = useForm<FullFlexibleSimulationValues>({
    defaultValues: {
      gender: valuesFromRecommender.gender,
      date_of_birth: valuesFromRecommender.date_of_birth,
      compensation_plan: '',
      death_insured_capital: 0,
      savings_option: SavingsOption.savings_capacity,
      goal_amount: transformCLPToUF(
        valuesFromRecommender.goal
        || valuesFromRecommender.university_cost
        || valuesFromRecommender.ideal_pension
      ),
      time_frame: 0,
      monthly_savings: 0,
      rentability_rate: 1,
      severe_diesase_assurance_rate: null,
      extra_compensation_insured: {
        itp: false,
        severe_disease: false,
      },
    },
    resolver: zodResolver(fullFlexibleValidationSchema(minimumCapital)),
  });
  const {
    time_frame, date_of_birth, gender, extra_compensation_insured, severe_diesase_assurance_rate, savings_option,
  } = methods.watch();

  const minimumDeathCapital = useMinimumCapital(
    {
      itp: extra_compensation_insured.itp,
      serious_illness_percentage: severe_diesase_assurance_rate?.value ?? 0,
      accidental_death: false, // Full Flexible does not provide `accidental_death` input
    },
    {
      date_of_birth,
      gender: gender as keyof typeof GenderOptions,
      product: 'FULL_FLEXIBLE',
    }
  );

  const handleShowSevereDiseaseAssuranceRateSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const defaultOption = SevereDiseaseAssuranceRate.asSelectOptions()[0];

    if (event.target.checked) {
      methods.setValue('severe_diesase_assurance_rate', defaultOption);
      return;
    }

    methods.setValue('severe_diesase_assurance_rate', null);
  };

  const handleTriggerErrors = () => methods.formState.errors;

  const onSubmit = async (values: FullFlexibleSimulationValues) => {
    await handleSubmitSimulation(values);
    setRepeatSimulation(true);
  };

  useEffect(() => {
    setMinimumCapital(minimumDeathCapital);
  }, [minimumDeathCapital]);

  useEffect(() => {
    methods.clearErrors('date_of_birth');
  }, [gender, methods]);

  // Resets opposite fields based on the current `savings_option` selection
  // Also clears errors for `goal_amount` share field to avoid keep error messages stale
  useEffect(() => {
    methods.clearErrors('goal_amount');

    if (savings_option === SavingsOption.savings_capacity) {
      methods.resetField('time_frame');
      return;
    }

    methods.resetField('monthly_savings');
  }, [savings_option]);

  return (
    <FormProvider {...methods}>
      <form className="d-flex flex-column gap-2" onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField<FullFlexibleSimulationValues>
          id="rut"
          label="¿Cuál es su RUT?"
          path="rut"
          type="text"
          placeholder="11.111.111-1"
          onChange={handleRutChange}
        />

        <DatepickerField<FullFlexibleSimulationValues>
          id="date_of_birth"
          label="¿Cuál es tu fecha de nacimiento?"
          path="date_of_birth"
        />

        <RadioField<FullFlexibleSimulationValues>
          label="¿Cuál es tu género?"
          options={Genders.asRadioOptions({ omit: ['OTHER'] })}
          path="gender"
        />

        <RadioBoxField<FullFlexibleSimulationValues>
          options={SavingsTypeOptions.asRadioBoxOptions({ select: ['savings_capacity', 'goal_value'] })}
          path="savings_option"
          label="¿Cómo te gustaría ahorrar?"
        />

        {savings_option === SavingsOption.savings_capacity && (
          <NumericFormatField<FullFlexibleSimulationValues>
            id="monthly_savings"
            label="¿Cuánto puedes ahorrar al mes?"
            path="monthly_savings"
            prefix="UF "
            placeholder="UF"
          />
        )}

        {savings_option === SavingsOption.goal_value && (
          <NumericFormatField<FullFlexibleSimulationValues>
            id="goal_amount"
            label="¿Cuál es el valor de tu objetivo?"
            path="goal_amount"
            prefix="UF "
            placeholder="UF"
          />
        )}

        {savings_option === SavingsOption.savings_capacity && (
          <NumericFormatField<FullFlexibleSimulationValues>
            id="goal_amount"
            label="¿Cuál es el valor de tu objetivo? (Opcional)"
            path="goal_amount"
            prefix="UF "
            placeholder="UF"
          />
        )}

        {savings_option === SavingsOption.goal_value && (
          <NumericFormatField<FullFlexibleSimulationValues>
            id="time_frame"
            label="¿En cuánto tiempo quieres lograrlo?"
            path="time_frame"
            suffix={time_frame === 1 ? ' mes' : ' meses'}
            placeholder="Meses"
          />
        )}

        <div>
          <div className="mb-2">
            <NumericFormatField<FullFlexibleSimulationValues>
              hideErrorMessage
              id="rentability_rate"
              label="Rentabilidad real"
              path="rentability_rate"
              suffix=" %"
              min={1}
              max={5}
            />
          </div>

          <SliderField<FullFlexibleSimulationValues>
            min={1}
            max={5}
            step={0.5}
            path="rentability_rate"
          />
        </div>

        <RadioField<FullFlexibleSimulationValues>
          label="¿Cuál es el plan de indemnización?"
          options={CompensationPlan.asRadioOptions()}
          path="compensation_plan"
        />

        <NumericFormatField<FullFlexibleSimulationValues>
          id="death_insured_capital"
          label="Capital asegurado de fallecimiento"
          path="death_insured_capital"
          prefix="UF "
          placeholder="UF"
          // TODO: UF amount should be computed by backend and obtained via backend
          helpMessage={minimumDeathCapital ? `El capital mínimo debería ser de UF ${minimumDeathCapital}.` : undefined}
        />

        <CheckboxField<FullFlexibleSimulationValues, Extract<ExtraCompensationInsuredOptions, 'itp' | 'several_diseases'>>
          label="¿Agrega otro capital asegurado?"
          options={ExtraCompensationInsured.asCheckboxOptions({ options: ['itp', 'several_diseases'] })}
          pathsMap={{
            several_diseases: 'extra_compensation_insured.severe_disease',
            itp: 'extra_compensation_insured.itp',
          }}
          onChangeMap={{
            several_diseases: handleShowSevereDiseaseAssuranceRateSelect,
          }}
          extraClassName="gap-5"
        />

        {extra_compensation_insured.severe_disease && (
          <SelectField<FullFlexibleSimulationValues>
            id="severe_disease_assurance_rate"
            options={SevereDiseaseAssuranceRate.asSelectOptions()}
            path="severe_diesase_assurance_rate"
          />
        )}

        <button onClick={handleTriggerErrors} type="submit" className="btn btn-secondary text-white py-3 w-100 fw-bold mt-4">
          {repeatSimulation ? 'Volver a simular' : 'Simular'}
        </button>
      </form>
    </FormProvider>
  );
}
