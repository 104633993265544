import React from 'react';
import { Modal } from 'react-bootstrap';
import { formatUf } from '../../utils/formatters';
import { MAX_UF_SAVINGS, REGIME_A_MAX_YEARS_SAVINGS } from './useSavingsExceededModal';

type SavingsExceededModalProps = {
  isOpen: boolean
  handleClose: () => void
};

export function SavingsExceededModal({ isOpen, handleClose }: SavingsExceededModalProps) {
  return (
    <Modal size="sm" show={isOpen} onHide={handleClose} centered>
      <Modal.Header className="px-6 pt-6" closeButton />

      <Modal.Body className="text-center px-6 pb-6">
        <img className="mb-4" src="/static/img/illustrations/office-work.svg" width={100} height={100} alt="" />
        <h2 className="fs-6 text-primary mb-4">¡Atención!</h2>
        <p className="text-center text-subtitle mb-6">
          El valor del ahorro
          {' '}
          <span className="fw-bold">
            excede las
            {' '}
            {formatUf(MAX_UF_SAVINGS)}
            {' '}
            al mes
          </span>
          ,
          tope legal de beneficio tributario via empleador para
          {' '}
          <span className="fw-bold">Régimen tributario B</span>
          .
          Para lograr este nivel de ahorro debes indicarle al cliente que
          {' '}
          <span className="fw-bold">
            considere
            invertir en APV régimen A y/o con depósitos convenidos
          </span>
          {' '}
          con tope de
          {' '}
          {formatUf(REGIME_A_MAX_YEARS_SAVINGS)}
          {' '}
          al año.
        </p>

        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <button type="button" onClick={handleClose} className="w-100 btn btn-primary">Entendido</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
